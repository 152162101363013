<template>
  <div class="container">
    <h2 class="title">Map of call numbers</h2>
    <div v-if="loading">
      <img src="@/assets/ajax-loader.gif"/>
    </div>
    <canvas v-else id="book-chart"></canvas>
    <button @click="goUp" v-show="showUpButton" class="button is-primary">Go up a level</button>
  </div>
</template>

<script>
  import Chart from 'chart.js'
  import bookChartData from '@/chartData.js';
  export default {
    data() {
      return {
        loading: true,
        showUpButton: false,
        chartData: bookChartData,
        call_numbers: []
      }
    },
    computed: {
      scope () {
        return this.chartData.data.scope
      },
      precision () {
        return this.chartData.data.precision
      }
    },
    watch: {
      precision () {
        console.log('watched precision')
        this.rescale(this.chartData.data.scope, this.chartData.data.precision)
      }
      
    },
    name: 'Map',
    methods: {
      goUp() {
        this.chartData.data.precision--

      },
      rescale(scale, precision) {
        console.log('loading from ' + scale + ',' + precision)
        this.loading = true
        this.axios.get('/v1/housekeeping/mapcallnos?precision=' + precision + '&range=' + scale)
          .then((response) => {
            this.call_numbers = response.data

            this.showUpButton = this.call_numbers[2] > -2
            this.loading = false
          })
          .then(() => {
            // this.chartData.data.precision = this.call_numbers[2]
            // console.log('chartData precision is now ' + this.chartData.data.precision)
            this.chartData.data.labels = this.call_numbers[1]
            this.chartData.data.labelvalues = this.call_numbers[0].map((x) => { return x[0]})
            this.chartData.data.datasets[0].data = this.call_numbers[0].map((x) => { return x[1]})
            this.createChart('book-chart', this.chartData)
           
        })
      },
      createChart(chartId, chartData) {
        const ctx = document.getElementById(chartId);
        const myChart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
        });
      }
    },
    mounted () {
      this.axios.get('/v1/housekeeping/mapcallnos')
        .then((response) => {
          this.call_numbers = response.data
          this.loading = false
        })
        .then(() => {
          this.chartData.data.precision = this.call_numbers[2]
          this.chartData.data.labels = this.call_numbers[1]
          this.chartData.data.labelvalues = this.call_numbers[0].map((x) => { return x[0]})
          this.chartData.data.datasets[0].data = this.call_numbers[0].map((x) => { return x[1]})
          this.createChart('book-chart', this.chartData)
          
      })
    }
  }  
</script>
  