export const bookChartData = {
  type: 'horizontalBar',
  data: {
    scope: null,
    precision: -2,
    labels: [],
    labelvalues: [],
    datasets: [
      {
        label: '# of books in each classification range',
        data: [],
        backgroundColor: 'rgba(54,73,93,.5)',
        borderColor: '#36495d',
        borderWidth: 3
      }
    ]
  },
  options: {
    events: ['click'],
    onClick: function (event, activeElements) {
      if (activeElements.length) {
        var element = this.getElementAtEvent(event);
        var index = element[0]._index;
        var _datasetIndex = element[0]._datasetIndex;
        // console.info(this.data.labelvalues[index]);
        // console.info(this.data.datasets[_datasetIndex].label);
        this.data.scope = this.data.labelvalues[index]
        console.info('setting scope to ' + this.data.scope)
        this.data.precision++;
        console.info('setting precision to ' + this.data.precision)

      }
    },
    
    responsive: true,
    lineTension: 1,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          padding: 25,
        }
      }]
    }
  }
}

export default bookChartData;